import { createSlice /*PayloadAction*/ } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface userState {
  currentUser: object | null | any;
  logingUser: boolean;
  resettingPassword: boolean;
  updatingProfilePicture: boolean;

  // login details
  username: string;
  password: string;
  otp: string;

  // reset password details
  newPassword: string;
}

const initialState: userState = {
  currentUser: null,
  logingUser: false,
  username: "",
  password: "",
  otp: "",
  newPassword: "",
  resettingPassword: false,
  updatingProfilePicture: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoginUser: (state, action) => {
      state.logingUser = action.payload;
    },
    setResettingPassword: (state, action) => {
      state.resettingPassword = action.payload;
    },
    setUser: (state, action) => {
      state.currentUser = action.payload;
      localStorage.setItem("currentStudent", JSON.stringify(action.payload));
    },
    setRefreshToken: (state, action) => {
      localStorage.setItem("refreshToken", JSON.stringify(action.payload));
    },
    restoreCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setUpdatingProfilePicture: (state, action) => {
      state.updatingProfilePicture = action.payload;
    },
    getAccountDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
    logoutUser: () => {
      localStorage.clear();
      window.location.replace(`/`);
    },
  },
});

export const {
  setUser,
  setLoginUser,
  restoreCurrentUser,
  getAccountDetails,
  logoutUser,
  setUpdatingProfilePicture,
  setResettingPassword,
  setRefreshToken,
} = userSlice.actions;
export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
